import React, { Fragment } from 'react'
import './timeline.css'
import { Tag, Avatar, Sticky, Button, Icon } from 'gestalt'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Lottie from 'lottie-react'
import smile from './smile.json'

const Timeline = ({ experience, tags }) => (
  <section className="resume-section experience-section mb-5">
    <Button
      role="link"
      href="https://drive.google.com/file/d/1C7GeMHf6ngG3NUZzaKKGilwaPYPSjCgT/view?usp=sharing"
      text="Download Resume PDF"
      color="transparent"
      iconEnd="download"
    />
    <div className="mt-3 resume-section-content">
      <div className="resume-timeline position-relative">
        {experience &&
          experience.map((exp, i) => {
            return (
              <article
                key={i}
                className="resume-timeline-item position-relative pb-4"
              >
                <Sticky top={0}>
                  <div className="resume-timeline-item-header mb-2">
                    <div className="d-flex">
                      <Avatar
                        name="Company Avatar"
                        size="md"
                        src={exp.logoUrl.logoUrl}
                      />
                      <h3
                        className="resume-position-title font-weight-bold"
                        style={{ marginLeft: '10px' }}
                      >
                        {exp.position}
                        <span
                          style={{
                            fontSize: '1rem',
                            fontWeight: '400',
                            marginLeft: '5px',
                            display: 'flex',
                          }}
                        >
                          @ {exp.companyName}
                        </span>
                      </h3>
                    </div>
                  </div>
                </Sticky>
                <div className="resume-timeline-item-desc">
                  <p className="subtext">
                    {exp.startDate} - {exp.endDate || ' Present'}
                  </p>
                  {renderRichText(exp.description)}
                  <p className="levelup">
                    <span className="levelup-text">
                      <Lottie
                        animationData={smile}
                        style={{ width: '30px', float: 'left' }}
                      />
                      <strong>Level-ups: </strong>
                      {exp.skills}
                    </span>
                  </p>
                </div>
              </article>
            )
          })}
      </div>
    </div>
  </section>
)

export default Timeline
