import React from "react"
import { graphql } from "gatsby"
import Ticker from "react-ticker"
import { Icon } from 'gestalt'

const Toolkit = ({ tools }) => (

<div className="tools-section">

{/* <Ticker>
      {({ index }) => (
        <> */}
          <p style={{marginRight:"100px"}}><span className="wavy" style={{fontWeight:500,marginRight:"5px"}}>My Current Toolkit: </span><i>{tools}</i></p>
        {/* </>
      )}
</Ticker> */}

    
</div>
)

export default Toolkit
