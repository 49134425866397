import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Timeline from '../components/timeline'
import Toolkit from '../components/tools'
import ArticlePreview from '../components/article-preview'
import { documentToReactComponents} from '@contentful/rich-text-react-renderer'



class RootIndex extends React.Component {
  render(data) {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const experience = get(this, 'props.data.allContentfulExperience.nodes')
    const [author] = get(this, 'props.data.allContentfulPerson.nodes')
    const tools = get(this, 'props.data.allContentfulTools.nodes')[0]
    const tags = get (this, 'props.data.allContentfulExperienceLevelUpsJsonNode')

    return (
      <Layout location={this.props.location}>
        <Hero
          image={author.heroImage.gatsbyImageData}
          title={author.name}
          content={author.shortBio.shortBio}
          company={author.company}
        />   
        <Toolkit tools={tools.tools}/> 
        <Timeline experience={experience} tags={tags} />
        <h2 className="tools-section tools-section-header">My Recent Musings</h2>
        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  
  query HomeQuery {
    allContentfulExperienceLevelUpsJsonNode {
      nodes {
        tags
      }
    }
    allContentfulExperience(sort: {order: DESC, fields: startDate}) {
      nodes {
        companyName
        position
        skills
        startDate(formatString: "MMMM YYYY")
        endDate(formatString: "MMMM YYYY")
        description {
          raw
          
        }
        logoUrl {
          logoUrl
        }
      }
    }
    allContentfulTools {
        nodes {
          tools
        }
      }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      nodes {
        bio {
          bio
        }
        name
        shortBio {
          shortBio
        }
        personalBio {
          raw
        }
        title
        company
        heroImage: image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
          )
        }
      }
    }
  }
`
